export const CONSULTINGS = [
    {
        area: "Technical Assessment",
        description: "Deep technical assessment to help with decision making, let it be for strategic acquisition or product funding/investment or periodic evaluation. Different consulting options, with comprehensive reports and recommendations for the stakeholders to make fast and right decisions. Write to me with the purpose and scope of the assessment for consultation."
    },
    {
        area: "Technology Advisory",
        description: "No matter how big or small the product/platform is, a good architecture & design drives a lot of parameters from scalability to cost to maintenance to enhancements. \"First things first\" helps an organization to build the product \"right\". Write to me with the details for consultation."
    },
    {
        area: "Corporate Training",
        description: "Upskill your team with technology training to improve the productivity and quality. Write to me with the details for consultation."
    }
    ];
 
